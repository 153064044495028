import create from "zustand";

export const useAvailabilityStore = create((set) => ({
  breakDuration: null,
  setBreakDuration: (breakDuration) => {
    set((state) => ({ breakDuration }));
  },
  selectedUser: null,
  setSelectedUser: (selectedUser) => {
    set((state) => ({ selectedUser }));
  },
  lastSelectedTimeZone: null,
  lastSelectedSlots: [],
  currentTimeZoneOnCopySlots: null,
  setLastSelectedSlotsAndTimeZone: ({ slots, timeZone, currentTimeZoneOnCopySlots }) => {
    set((state) => ({
      lastSelectedTimeZone: timeZone,
      lastSelectedSlots: slots,
      currentTimeZoneOnCopySlots,
    }));
  },
  resetSelectedUser: () => set((state) => ({ selectedUser: null })),
  resetBreakDuration: () => set((state) => ({ breakDuration: null })),
  resetAvailabilityState: () => {
    set((state) => ({
      lastSelectedTimeZone: null,
      lastSelectedSlots: [],
      breakDuration: null,
      selectedUser: null,
      currentTimeZoneOnCopySlots: null,
    }));
  },
}));
