import { isEmptyArrayOrFalsey } from "../services/typeGuards";
import { getObjectEmail } from "./objectFunctions";

export function getZoomHostEmail(zoomMeeting) {
  return zoomMeeting?.host_email;
}

export function getZoomJoinURL(zoomMeeting) {
  return zoomMeeting?.join_url;
}

// scheduler comes in as below
// {
//   "4": {
//       "email": "mike@vimcal.com",
//       "host_email": "mike@vimcal.com",
//       "user_id": 4,
//       "token": "uBfWrFEnHhabyKvqg",
//       "schedulers": [
//           {
//               "id": "nCVTFUa4QkqkLCbJWsi0OQ",
//               "email": "alex@vimcal.com",
//               "pmi": 5906687175
//           },
//           {
//               "id": "_f8jxVIHSO-t4cuU17WqQA",
//               "email": "seamus@vimcal.com",
//               "pmi": 4335695133
//           }
//       ]
//   }
// }
export function getZoomSchedulerEmails(schedulers) {
  try {
    const emails = [];
    Object.values(schedulers).forEach((scheduler) => {
      if (!isEmptyArrayOrFalsey(scheduler?.schedulers)) {
        const schedulerEmails = scheduler.schedulers
          .map((scheduler) => getObjectEmail(scheduler))
          .filter((email) => email);
        emails.push(...schedulerEmails);
      }
    });
    return emails;
  } catch (error) {
    return [];
  }
}

export const ZOOM_FIND_LOCAL_NUMBER = "https://us06web.zoom.us/u/kcac9diqY";
export const ZOOM_FIND_LOCAL_NUMBER_WITH_A_WRAPPER = `${ZOOM_FIND_LOCAL_NUMBER}</a>`;

export function isConferenceDataUniqueZoomMeeting(conferenceData) {
  if (isEmptyArrayOrFalsey(conferenceData?.entryPoints)) {
    return false;
  }
  // if there's meeting code, it's not personal
  return conferenceData.entryPoints.some(
    (entryPoint) => entryPoint?.meetingCode
  );
}

export function stripOutZoomFromLocationAndDescription({
  zoomMeeting,
  location,
  description,
}) {
  if (!zoomMeeting) {
    return { location, description };
  }
  const { join_url } = zoomMeeting;
  const updatedState = {
    updatedLocation: location,
    updatedDescription: description,
    hasUpdatedDescription: false,
  };
  if (location?.includes(join_url)) {
    updatedState.updatedLocation = "";
  }
  if (description?.includes(join_url)) {
    if (description.includes(ZOOM_FIND_LOCAL_NUMBER_WITH_A_WRAPPER)) {
      // on edit
      const startIndex = description.indexOf("Join Zoom Meeting");
      const endIndex = description.indexOf(
        ZOOM_FIND_LOCAL_NUMBER_WITH_A_WRAPPER
      );
      if (startIndex !== -1 && endIndex !== -1) {
        updatedState.updatedDescription =
          description.slice(0, startIndex) +
          description.slice(
            endIndex + ZOOM_FIND_LOCAL_NUMBER_WITH_A_WRAPPER.length
          ); // 66 is the length of the end marker
      }
      updatedState.hasUpdatedDescription = true;
    } else {
      const startIndex = description.indexOf("Join Zoom Meeting");
      const endIndex = description.indexOf(ZOOM_FIND_LOCAL_NUMBER);
      if (startIndex !== -1 && endIndex !== -1) {
        updatedState.updatedDescription =
          description.slice(0, startIndex) +
          description.slice(endIndex + ZOOM_FIND_LOCAL_NUMBER.length); // 66 is the length of the end marker
        updatedState.hasUpdatedDescription = true;
      }
    }
  }
  return updatedState;
}

export function isZoomMeetingPersonalLink(zoomMeeting) {
  // Instant Meeting (Type 1): A meeting that starts immediately.
  // Scheduled Meeting (Type 2): A one-time meeting set for a specific time.
  // Recurring Meeting with No Fixed Time (Type 3): A recurring meeting without a set schedule.
  // Personal Meeting Room (Type 4): A dedicated, permanent meeting room assigned to a user.
  // if type === 4 then it's a personal meeting link
  // only accept unique zoom meetings
  return zoomMeeting?.type === 4;
}
