import {
  isOSSchemeDarkMode,
} from "../../services/commonUsefulFunctions";
import {
  SOPHIE_PROFILE_PICTURE,
  DYLAN_HEAD_SHOT,
} from "../../services/globalVariables";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import {
  isUserInDarkMode,
  shouldMatchOSSetting,
} from "../../lib/settingsFunctions";
import { getMasterAccountID } from "../../lib/userFunctions";
import { isInBucket } from "../../lib/intFunctions";

export const ONBOARDING_GLOW_TYPE = {
  CREATE_EVENT_BUTTON: "create_event_button",
  CREATE_AVAILABILITY_BUTTON: "create_availability_button",
} as const;

export const ROLES = {
  FOUNDER: "Founder",
  C_LEVEL: "C-level",
  DESIGNER: "Designer",
  DEVELOPER: "Developer",
  EXECUTIVE: "Executive",
  MANAGER: "Manager",
  MARKETING: "Marketing",
  SALES: "Sales",
  EXECUTIVE_ASSISTANT: "Executive Assistant",
  CUSTOMER_SUPPORT: "Customer Support",
  INVESTOR: "Investor",
  OTHER: "Other",
} as const;

export const ALL_ROLES: Readonly<ValueOf<typeof ROLES>[]> = [
  ROLES.FOUNDER,
  ROLES.EXECUTIVE,
  ROLES.MANAGER,
  ROLES.INVESTOR,
  ROLES.EXECUTIVE_ASSISTANT,
  ROLES.SALES,
  ROLES.MARKETING,
  ROLES.OTHER,
];

export function isDefaultThemeDarkMode({ masterAccount }: { masterAccount: MasterAccount }) {
  if (shouldMatchOSSetting({ masterAccount })) {
    return isOSSchemeDarkMode();
  } else if (isUserInDarkMode({ masterAccount })) {
    // if current user is already set to dark mode
    return true;
  } else if (isOSSchemeDarkMode()) {
    return true;
  } else {
    return false;
  }
}

export function getPersonalOnboardingLink(_masterAccount?: OptionalOrEmptyObject<MasterAccount>) {
  return "https://calendly.com/vimcal/onboarding";
}

export function getPersonalOnboardingSpecialist(masterAccount: OptionalOrEmptyObject<MasterAccount>) {
  // const TESTING_INFO = {token: "b7c5633064531f2f15ffba78", photo: SOPHIE_PROFILE_PICTURE}; // this should only be used for testing
  const SOPHIE_INFO = {
    token: "2afcb2c987892faf5e497b50",
    photo: SOPHIE_PROFILE_PICTURE,
  };
  const DYLAN_INFO = { token: "c29q5WaMbaC4zQZ7", photo: DYLAN_HEAD_SHOT };

  // if (isTestEnvironment()) {
  //   return TESTING_INFO;
  // }

  if (isEmptyObjectOrFalsey(masterAccount) || !masterAccount.id) {
    return DYLAN_INFO; // default to Dylan
  }
  const isEven = masterAccount.id % 2 === 0;

  if (isEven) {
    return DYLAN_INFO;
  } else {
    return SOPHIE_INFO;
  }
}

export const PRODUCTS = {
  MIXMAX: "Mixmax",
  DOODLE: "Doodle",
  GOOGLE_CALENDAR: "Google calendar",
  OUTLOOK: "Outlook",
  CALENDLY: "Calendly",
  SUPERHUMAN: "Superhuman",
  CAL_COM: "Cal.com",
  NOTION_CALENDAR: "Notion Calendar",
  FANTASTICAL: "Fantastical",
  OTHER: "Other",
} as const;

export const OTHER_PRODUCTS: Readonly<ValueOf<typeof PRODUCTS>[]> = [
  PRODUCTS.CALENDLY,
  PRODUCTS.SUPERHUMAN,
  PRODUCTS.NOTION_CALENDAR,
  PRODUCTS.DOODLE,
  PRODUCTS.FANTASTICAL,
  PRODUCTS.CAL_COM,
  PRODUCTS.OTHER,
];

// Options in this list will use the round robin Calendly link.
// Otherwise Alex's link will be used.
export const EA_COUNT_ROUND_ROBIN_OPTIONS = ["1-5"] as const;

export const EA_COUNT_OPTIONS = [
  ...EA_COUNT_ROUND_ROBIN_OPTIONS,
  "6-10",
  "11-25",
  "26-50",
  "51-100",
  "100+",
] as const;

// previously, we forced all users to go through the manual onboarding if they used calendly or superhuman.
// now, we allocate 1/3 of them to the self onboarding
export function isInSelfOnboardingExperimentBucket({ masterAccount }) {
  const id = getMasterAccountID(masterAccount);
  return isInBucket({ id, percentage: 67 }); // allocate 67% to self serve for those who were previously forced to go through onboarding
}
