import * as Sentry from "@sentry/browser";

import { isOutlookUser } from "../lib/outlookFunctions";
import { formatEmail, isSameEmail } from "../lib/stringFunctions";
import { BACKEND_SETTINGS_NAMES } from "../lib/vimcalVariables";
import { getCalendarIsPrimary, getCalendarUserCalendarID } from "./calendarAccessors";
import { MAESTRO_DELEGATION_TYPES } from "./globalMaestroVariables";
import { getMagicLinkEmail, getUserConnectedAccountDetails } from "./maestro/maestroAccessors";
import { getSettingValue } from "./settingsAccessors";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "./typeGuards";
import { sendBreadcrumbToSentry } from "./commonUsefulFunctions";
import { getMasterAccountEmail, getUserEmail, getUserToken } from "../lib/userFunctions";
import { getObjectEmail } from "../lib/objectFunctions";
import { isLocal } from "./devFunctions";

/*********************/
/* Maestro Functions */
/*********************/

/**
 * @param {MasterAccount | Record<string, never> | null | undefined} masterAccount
 */
export function isUserMaestroUser(masterAccount) {
  if (isEmptyObjectOrFalsey(masterAccount) || !masterAccount.scheduling_for_others) {
    return false;
  }
  return masterAccount.scheduling_for_others;
}

export function isUserOutlookMaestro({  masterAccount, user }) {
  return isUserMaestroUser(masterAccount) && isOutlookUser(user);
}

/*****************************/
/* User Delegation Functions */
/*****************************/

// delegated users can either have limited or full access
// limited = the EA had write access and converted a calendar of an exec to that separate delegated user. they did not login with email/password
// full = they logged in as that exec with the exec’s google email/password
// majority case is limited
export function isUserLimitedAccess(user) {
  if (isEmptyObjectOrFalsey(user) || !user.delegation) {
    return false;
  }

  return user.delegation === MAESTRO_DELEGATION_TYPES.LIMITED;
}

export function isUserDelegatedUser(user) {
  if (isEmptyObjectOrFalsey(user) || !user.delegation) {
    return false;
  }
  return !!user.delegation;
}

export function isUserMagicLinkUser({ user }) {
  if (isEmptyObjectOrFalsey(user) || !user.connected_account_token) {
    return false;
  }

  return true;
}

export function isMaestroUserOnDelegatedAccount({ masterAccount, user }) {
  return isUserMaestroUser(masterAccount) && isUserDelegatedUser(user);
}

export function isUserExecutiveUser({ user }) {
  return isUserDelegatedUser(user) || isUserMagicLinkUser({ user });
}

/**
 * @param {Object} options
 * @param {User[]} options.allLoggedInUsers
 * @returns {User[]}
 */
export function getAllExecutives({ allLoggedInUsers }) {
  if (!allLoggedInUsers) {
    return [];
  }

  return allLoggedInUsers.filter((user) => isUserExecutiveUser({ user }));
}

/*****************************/
/* Zoom Delegation Functions */
/*****************************/

export function isUserBeingScheduledFor({user, schedulers, masterAccount}) {
  if (isEmptyObjectOrFalsey(user) || !user.email || !schedulers || !isUserMaestroUser(masterAccount)) {
    return false;
  }

  return !!getZoomSchedulerUserID({user, schedulers});
}

export function getDelegatedZoomPMI({ user, schedulers }) {
  if (isEmptyObjectOrFalsey(user) || isEmptyObjectOrFalsey(schedulers)) {
    return;
  }

  /* We call this function after checking isUserBeingScheduledFor so this should always have a value */
  const matchingUserId = getZoomSchedulerUserID({ user, schedulers });
  return schedulers[matchingUserId]?.schedulers?.find((scheduler) => isSameEmail(scheduler.email, user.email))?.pmi;
}

function getZoomSchedulerUserID({user, schedulers}) {
  try {
    const userEmail = formatEmail(user?.email);
    if (!userEmail || isEmptyObjectOrFalsey(schedulers)) {
      return null;
    }
    // Extract all matching schedulers with the given user email
    const matchingSchedulers = Object.keys(schedulers).filter((key) => {
      const schedulerGroup = schedulers?.[key]?.schedulers;
      if (!schedulerGroup) {
        return false;
      }

      const allEmailsForKey = schedulerGroup.map((scheduler) => getObjectEmail(scheduler));
      return allEmailsForKey.includes(userEmail);
    });

    if (isEmptyArrayOrFalsey(matchingSchedulers)) {
      return null;
    }

    // Find the scheduler with the latest updated_at time
    const latestSchedulerKey = matchingSchedulers.reduce((latestKey, currentKey) => {
      const latestUpdatedAt = schedulers[latestKey]?.updated_at;
      const currentUpdatedAt = schedulers[currentKey]?.updated_at;

      return latestUpdatedAt > currentUpdatedAt ? latestKey : currentKey;
    });

    return latestSchedulerKey;
  } catch (error) {
    return null;
  }
}

export function getZoomSchedulerID({ user, schedulers }) {
  const userID = getZoomSchedulerUserID({user, schedulers});
  if (!userID) {
    return {};
  }

  return {user_id: userID};
}

/************************/
/* Magic Link Functions */
/************************/

export function doesMagicLinkExist({ magicLink }) {
  return !isEmptyObjectOrFalsey(magicLink);
}

export function getPrimaryUserCalendarIDByMagicLinkEmail({ magicLink, magicLinkAllCalendars }) {
  if (isEmptyObjectOrFalsey(magicLink) || isEmptyArrayOrFalsey(magicLinkAllCalendars)) {
    return "";
  }

  /* Calendars belonging to the email invited in magic link */
  const calendars = magicLinkAllCalendars[getMagicLinkEmail({ magicLink })];
  const primaryCalendar = calendars?.find(calendar => getCalendarIsPrimary(calendar));

  return getCalendarUserCalendarID(primaryCalendar) ?? "";
}

export function getDelegatedUserAuthenticatedUser(user) {
  return user?.authenticated_user;
}

export function getConnectedAccountUserName({ user }) {
  const connectedAccountDetails = getUserConnectedAccountDetails({ user });

  /* We should NEVER enter here */
  if (isEmptyArrayOrFalsey(connectedAccountDetails)) {
    sendBreadcrumbToSentry({
      category: "", // What should this be?
      message: "[Username] Missing connected account details.",
      data: {
        backendSettingName: BACKEND_SETTINGS_NAMES.USERNAME,
        user,
      },
      level: Sentry.Severity.Warning,
    });

    return {
      firstName: "",
      lastName: "",
      fullName: "",
      userName: "",
    };
  }

  const firstName = user?.first_name ?? "";
  const lastName = user?.last_name ?? "";
  // User user.full_name else check if we have first and last
  // If we do, then use `${firstName} ${lastName}` instead
  const fullName = (firstName && lastName) ? `${firstName} ${lastName}` : (user?.full_name ?? "");

  return {
    firstName: firstName,
    lastName: lastName,
    fullName: fullName,
    userName: getSettingValue({
      backendSettingName: BACKEND_SETTINGS_NAMES.USERNAME,
      user,
    }) ?? "",
  };
}

export function groupByMyAccountAndExecs({ allLoggedInUsers }) {
  let myAccounts = [];
  let execAccounts = [];
  allLoggedInUsers.forEach((user) => {
    /* Proxy users */
    if (isUserLimitedAccess(user)) {
      execAccounts = execAccounts.concat(user);
      return;
    }

    const connectedAccountDetails = getUserConnectedAccountDetails({ user });
    /* User is from EA's own master account */
    if (isEmptyObjectOrFalsey(connectedAccountDetails)) {
      myAccounts = myAccounts.concat(user);
      return;
    }
    execAccounts = execAccounts.concat(user);
  });
  return {
    myAccounts,
    execAccounts,
  };
}

export function groupAndSortAllLoggedInUsersByExecutive({ allLoggedInUsers, currentUser, masterAccount }) {
  const masterAccountEmail = getMasterAccountEmail({ masterAccount });
  let executivesWithUsers = {
    [masterAccountEmail]: {
      masterAccount,
      users: [],
    },
  };

  allLoggedInUsers.forEach((user) => {
    /* Proxy users */
    if (isUserLimitedAccess(user)) {
      const userEmail = getUserEmail(user);
      executivesWithUsers[userEmail] = {
        /* Fake a master account */
        masterAccount: {
          first_name: user?.first_name ?? "",
          last_name: user?.last_name ?? "",
          full_name: user?.full_name ?? "",
          username: user?.username ?? "",
          stripe_email: userEmail,
          internal_profile_photo_url: user?.internal_profile_photo_url,
        },
        users: [user],
      };
      return;
    }

    const connectedAccountDetails = getUserConnectedAccountDetails({ user });
    /* User is from EA's own master account */
    if (isEmptyObjectOrFalsey(connectedAccountDetails)) {
      executivesWithUsers[masterAccountEmail]?.users?.push(user);
      return;
    }

    /* User belongs to an Executive */
    const executiveMasterAccount = connectedAccountDetails?.master_account;
    const executiveMasterAccountEmail = getMasterAccountEmail({ masterAccount: executiveMasterAccount });

    /* Master account has not been added to object */
    if (Object.keys(executivesWithUsers).indexOf(executiveMasterAccountEmail) === -1) {
      /* Add the base values to the array */
      executivesWithUsers[executiveMasterAccountEmail] = {
        masterAccount: executiveMasterAccount,
        users: [user],
      };
      return;
    }

    /* Master account has already been added - just need to push to user array */
    executivesWithUsers[executiveMasterAccountEmail]?.users?.push(user);
  });

  /* Sort user arrays */
  Object.values(executivesWithUsers).forEach(executiveWithUser => {
    const { users } = executiveWithUser;

    users.sort((userA, userB) => {
      if (getUserToken(userA) === getUserToken(currentUser)) {
        return -1;
      }

      if (getUserToken(userB) === getUserToken(currentUser)) {
        return 1;
      }

      return getUserEmail(userA) < getUserEmail(userB) ? -1 : 1;
    });
  });

  /* Sort executives */
  return Object.values(executivesWithUsers).sort((executiveHashOne, executiveHashTwo) => {
    /* Current user is in executiveHashOne */
    if (executiveHashOne?.users?.find(user => getUserToken(user) === getUserToken(currentUser))) {
      return -1;
    }

    /* Current user is in executiveHashTwo */
    if (executiveHashTwo?.users?.find(user => getUserToken(user) === getUserToken(currentUser))) {
      return 1;
    }

    /* Sort alphabetically */
    const executiveHashOneName = getMasterAccountEmail({ masterAccount: executiveHashOne?.masterAccount });
    const executiveHashTwoName = getMasterAccountEmail({ masterAccount: executiveHashTwo?.masterAccount });
    return executiveHashOneName < executiveHashTwoName ? -1 : 1;
  });
}
