export const CALENDAR_CATEGORIES_ENDPOINT = "calendars/categories";
export const FETCH_CALENDAR_EVENTS_ENDPOINT = "calendars/events";
export const LATEST_GROUP_VOTE_LINKS = "group_vote_links";
export const GROUP_SPREADSHEET_LINKS_ENDPOINT = "group_spreadsheet_links";
export const CONVERT_TO_EA_ACCOUNT_ENDPOINT = "users/convert_to_ea_account";
export const ONBOARDING_RESPONSES_ENDPOINT = "onboarding_responses";
export const SEND_MOBILE_SIGNP_EMAIL_ENDPOINT = "users/send_post_sign_up_email";
export const GET_OUTLOOK_PREVIEW_EVENTS_AND_CALENDARS = "preview_events_sync";
export const GET_OUTLOOK_PREVIEW_EVENTS_WITHOUT_CALENDARS = "calendars/preview_events";
export const GET_MEET_WITH_EVENTS_END_POINT = "calendars/fetch_temporary_events";
export const GET_TEAM_TEMPORARY_EVENTS = "calendars/fetch_team_temporary_events";
export const GET_TEAM_TEMPORARY_PREVIEW_EVENTS = "calendars/fetch_team_temporary_preview_events";
export const GET_TEMPORARY_PREVIEW_EVENTS = "calendars/fetch_temporary_preview_events";
export const GET_CALENDAR_ONLY_ENDPOINT = "calendars_list_sync"; // GET
export const ZOOM_MEETING_BY_ZOOM_ID = "zoom_meetings/by_zoom_id";
export const GET_MULTIPLE_CALENDAR_ONLY_ENDPOINT = "calendars_list_sync_by_tokens"; // GET
export const ZOOM_LINK_ENDPOINT = "zoom_link";
export const GET_ROOM_AVAILABILITY = "rooms/available";
export const GET_LATEST_MASTER_ACCOUNT_ENDPOINT = "users/master_account";
export const UPDATE_SLOTS_SETTINGS_ENDPOINT = "slots/settings";
export const GET_LAST_12_WEEKS_OF_METRICS_ENDPOINT = "users/last-user-metrics";
export const ENRICH_CONTACTS_SOCIAL_LINKS_ENDPOINT = "contacts/enriched"; // this enriches the contact email with urls of social profiles
export const GET_PAGINATED_CONFERENCE_ROOMS = "rooms/paginated";

// v2 distro list endpoints
export const GET_USER_HAS_DISTRO_LIST_PERMISSION = "google_resources/groups/has_read_permission"; // get
export const IMPORT_DISTRO_LIST_GROUPS = "google_resources/groups/import_groups"; // post and async so response is null
export const LIST_DISTRO_GROUPS = "google_resources/groups/list"; // get

// The body should be a JSON object containing a `user_calendar_order` key. This key maps calendar IDs (as strings) to integer values, where the integers represent the desired order.
// #### Example Request Payload:
// {
//     “user_calendar_order”: {
//         “3a1c7eb46b0a7b5929f90f25c45b1f68": 1,
//         “849985864f31c23be730c048cd1e167c”: 2,
//         “56fad149b78f7cbc915b5776af6c037e”: 3,
//         “4008875f69668166edb11464c7a2f956”: 0,
//         “e2970c77b01f2fa8847234416f340491": 4
//     }
// }
export const UPDATE_CALENDAR_ORDER = "calendars/reorder_calendars"; // post v2

const CONTACTS_BASE_ENDPOINT = "contacts";
export const CONTACTS_ENDPOINTS = {
  /** v2 GET */
  PERSONAL: `${CONTACTS_BASE_ENDPOINT}/personal`,
  /** v2 GET */
  OTHER: `${CONTACTS_BASE_ENDPOINT}/other`,
} as const;

export const SSO_SAML_ENDPOINTS = {
  CHECK_IS_ENABLED: "sso/check_domain", // GET -> pass in {domain}. Response is {is_enabled: boolean }
  CREATE_IDENTIFY_PROVIDER_CONFIG: "sso/saml_account", // POST  -> pass in {saml_account_id (okta, saml1, etc)}
  GET_SSO_CONFIG: "sso/saml/idp_configuration",
  SSO_AUTHENTICATION: "users/sso_authentication",
  REDIRECT: "sso",
} as const;

const MAGIC_LINK_BASE_ENDPOINT = "magic_links";
export const MAGIC_LINK_ENDPOINTS = {
  CREATE_MAGIC_LINK: MAGIC_LINK_BASE_ENDPOINT, // POST
  GET_MAGIC_LINK: MAGIC_LINK_BASE_ENDPOINT, // GET
  CONNECT_MAGIC_LINK_ACCOUNT: `${MAGIC_LINK_BASE_ENDPOINT}/connect_accounts`, // POST
} as const;

const TEAM_PLAN_BASE_ENDPOINT = "team_plan";
export const TEAM_PLAN_ENDPOINTS = {
  /** v2 POST */
  CHECK_EMAIL: `${TEAM_PLAN_BASE_ENDPOINT}/check_email`,
  /** v2 GET */
  CHECK_INVITES: `${TEAM_PLAN_BASE_ENDPOINT}/check_invites`,
  /** v2 POST */
  CREATE_SHARING_LINK: `${TEAM_PLAN_BASE_ENDPOINT}/create_sharing_link`,
  /** v2 POST */
  INVITE_MEMBERS: `${TEAM_PLAN_BASE_ENDPOINT}/invite_members`,
  /** v2 POST */
  PREVIEW_INVITE_COST: `${TEAM_PLAN_BASE_ENDPOINT}/preview_invite_cost`,
  /** v2 POST */
  REMOVE_MEMBER: `${TEAM_PLAN_BASE_ENDPOINT}/remove_member`,
  /** v2 POST */
  RESPOND_INVITE: `${TEAM_PLAN_BASE_ENDPOINT}/respond_invite`,
  /** v2 PATCH */
  UPDATE_PROFILE: `${TEAM_PLAN_BASE_ENDPOINT}/profile`,
  /** v2 PATCH */
  UPDATE_ROLE: `${TEAM_PLAN_BASE_ENDPOINT}/role`,
} as const;

const STRIPE_BASE_ENDPOINT = "stripe";
export const STRIPE_ENDPOINTS = {
  /** v2 GET */
  BILLING: "billing",
  /** v1 PATCH */
  CANCEL_SUBSCRIPTION: `${STRIPE_BASE_ENDPOINT}/cancel_subscription`,
  /** v1 GET */
  SUBSCRIPTION_DETAILS: `${STRIPE_BASE_ENDPOINT}/subscription_details`,
  /**
   * v1 POST
   *
   * TODO: The master account ID isn't actually needed in the URL and is ignored by the backend.
   * Update the backend route to not require it, then remove it from the frontend.
   */
  SYNC_ACCOUNT_STATE: (masterAccountId: number) => `${STRIPE_BASE_ENDPOINT}/account_state/${masterAccountId}`,
  /** v2 POST */
  UNCANCEL_SUBSCRIPTION: `${STRIPE_BASE_ENDPOINT}/uncancel_subscription`,
  /**
   * v1 PATCH
   *
   * TODO: The master account ID isn't actually needed in the URL and is ignored by the backend.
   * Update the backend route to not require it, then remove it from the frontend.
   */
  UPDATE_PAYMENT_METHOD: (masterAccountId: number) => `${STRIPE_BASE_ENDPOINT}/${masterAccountId}/payment_method`,
  /**
   * v1 PATCH
   *
   * TODO: The master account ID isn't actually needed in the URL and is ignored by the backend.
   * Update the backend route to not require it, then remove it from the frontend.
   */
  UPDATE_PAYMENT_METHOD_WITHOUT_AUTH: (masterAccountId: number) => `${STRIPE_BASE_ENDPOINT}/${masterAccountId}/payment_method_without_auth`,
  /** v1 POST */
  UPGRADE_SUBSCRIPTION: `${STRIPE_BASE_ENDPOINT}/upgrade_subscription`,
  /** v1 POST */
  UPGRADE_SUBSCRIPTION_WITHOUT_AUTH: `${STRIPE_BASE_ENDPOINT}/upgrade_subscription_without_auth`,
  /** v1 GET */
  USER_NEEDS_CARD: `${STRIPE_BASE_ENDPOINT}/user_needs_card`,
} as const;
