import { sanitize } from "dompurify";
import linkifyHtml, { type Options as LinkifyOptions} from "linkifyjs/html";

export const LINKIFY_DEFAULTS: LinkifyOptions = {
  attributes: {
    rel: "noopener noreferrer",
  },
  className: "text-color-link",
  defaultProtocol: "http",
  events: {},
  format: function (value, type) {
    return value;
  },
  formatHref: function (href, type) {
    return href;
  },
  ignoreTags: [],
  nl2br: false,
  tagName: "a",
  target: {
    url: "_blank",
  },
  validate: true,
};

export function sanitizeStringAndLinkify(str: string | null) {
  if (!str) {
    return "";
  }

  return linkifyHtml(sanitize(str), LINKIFY_DEFAULTS);
}

export function sanitizeString(str: string | null) {
  if (!str) {
    return "";
  }
  return sanitize(str);
}
