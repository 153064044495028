import { ENVIRONMENTS } from "../lib/vimcalVariables";
import { isSafari } from "./commonUsefulFunctions";

export function isSafariAndLocal() {
  // useful for testing local safari which for some reason doesn't store cookies
  return isSafari() && isLocal();
}

export function isLocal() {
  if (!process.env.REACT_APP_SERVER_BASE_URL) {
    return true;
  }

  return process.env.REACT_APP_CLIENT_ENV === ENVIRONMENTS.LOCAL;
}

export function isStaging() {
  return process.env.REACT_APP_CLIENT_ENV === ENVIRONMENTS.STAGING;
}

export function devErrorPrint(error: unknown, label: unknown) {
  if (isLocal()) {
    console.error("dev error: ", label, error);
  }
}

export function devPrint(...args: unknown[]) {
  if (isLocal()) {
    console.log(...args);
  }
}
